.admin {
  height: 100vh;
  display: grid;
  grid-template-columns: 1.2fr 4fr;

  &_menu-drawer {
    background-color: #373435;
    display: grid;
    grid-template-rows: 25% 50%;

    > * {
      margin: auto;
    }

    &_navbar {
      ul {
        list-style-type: none;
        li {
          padding-bottom: 32px;
          a {
            text-decoration: none;
            color: white;
            font-size: 24px;
          }
        }
      }
      button {
        display: flex;
        color: rgba(20, 117, 162, 1);
        font-size: 16px;
        background: no-repeat;
        border: none; 
        border: 1px solid #1475a2;
        border-radius: 20px;
        padding: 7px 25px;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &_content {
    padding: 3vh 3vw;
    background-color: #e5e5e5;
    height: 100vh;
    display: grid;
    grid-row-gap: 4vh;
    grid-template-rows: 1.1fr 10fr;

    &_header {
      background-color: white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 2px solid #1475a2;

      &_title {
        font-weight: 600;
        font-size: 24px;
      }

      &_notifications {
        a{
          display: flex;
          align-items: center; 
          text-decoration: none;

          p {
            margin-right: 20px;
            color: black;
          }
        }
 
      }
    }

    &_inner {
      padding: 10vh 2vw 2vh;
      background-color: white;

      &-partners {
        padding: 0;
        display: grid;
        grid-template-rows: 15% 70%;

      }

      &_partners {
        width: 47.5%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: auto;
        align-content: flex-start;
        height: 100%;
        overflow: auto;

        &_item {
          padding: 1% 5%;
          position: relative;

          .deleteIcon {
            position: absolute;
            cursor: pointer;
            bottom: 0;
            right: -4px;
          }

          img{
            max-height: 90px;
          }
        }
      }

      &_add {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          display: flex;
          color: rgba(20, 117, 162, 1);
          font-size: 16px;
          background: no-repeat;
          border: none;
          cursor: pointer;

          img {
            margin-right: 10px;
          }
        }
      }

      table {
        height: 100%;
        width: 100%;
        border: 1px solid #c4c4c4;
        border-collapse: collapse;
        position: relative;

        tbody {
          display: block;
          height: 90.2%;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 4px;
            position: absolute;
            left: 0;
          }

          /* Track */

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background-color: #1475a2;
          }
        }

        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed; /* even columns width , fix width of table too*/
        }
        thead {
          height: 9.8%;
          width: calc(100% - 4px);
        }

        tr {
          height: 15%;
        }

        td,
        th {
          font-weight: normal;
          font-size: 16px;
          text-align: center;
          border: 1px solid #c4c4c4;
         word-wrap: break-word;
         padding: 0 10px;
        }
        .deleteButton{
          cursor: pointer;
        }
      }
    }
    &_mail {
      display: grid;
      grid-column-gap: 1vw;
      grid-template-columns: 1fr 2.5fr;
      overflow: auto;
      &_sidebar {
        background-color: white;
        overflow: auto;
        display: grid;
        grid-template-rows: auto 1fr;

        &_logo {
          margin: 11px 0 6.3vh 18px;
        }

        &_dialog-panel {
          overflow: auto;
          &_item {
            display: grid;
            grid-template-columns: auto 1fr;
            padding: 7px 27px 5px 11px;
            border-bottom: 2px solid #e5e5e5;
            cursor: pointer;
            input[type="checkbox"] {
              height: 24px;
              width: 24px;
              -webkit-appearance: none;
              background-color: rgba(229, 229, 229, 1);
              margin-right: 23px;
            }

            &_name-subject {
              flex-grow: 1;
              overflow: hidden;

              h2 {
                font-weight: 600;
                font-size: 16px;
              }

              p {
                font-weight: 350;
                font-size: 16px;
                color: rgba(139, 136, 146, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }

      &_inner {
        background-color: white;
        padding-top: 16%;
        padding-left: 11%;
        padding-right: 15.3%;
        overflow: auto;
        
        &_main-part {
          &_header {
            display: flex;
            align-items: center;

            h2 {
              font-weight: 600;
              font-size: 16px;
              margin-left: 13px;
            }
          }
          &_mail {
            margin-top: 26px;

            h2,
            p {
              font-weight: 350;
              font-size: 16px;
              color: #8b8892;
            }
            p {
              margin-top: 14px;
            }
          }

          &_send-mail {
            margin-top: 10%;

            textarea {
              border: 1px solid #e5e5e5;
              border-radius: 15px;
              width: 100%;
              padding: 20px;
              outline: none;
              resize: none;
            }
          }
          &_submit {
            margin-top: 27px;
            display: flex;
            justify-content: flex-end;

            button {
              cursor: pointer;
              border: none;
              outline: none;
              background: none;
              color: #727376;
              padding: 14px 8px 15px 16px;
              font-size: 12px;
              border: 1px solid #e5e5e5;
              
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
