 
// $deleteIconPath:url();
.auth {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &_inner {
    width: 47.083vw;
    border: 1px solid rgba(229, 229, 229, 1);
    padding: 5vh 0;
    
    &-success{
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      text-align: center;

      img{
        width: 30%;
      }

      p{
        font-size: 28px;
        margin-bottom: 50px;
      }
    }

    &_header {
      border-bottom: 2px solid rgba(20, 117, 162, 1);
      padding-bottom: 10px;
      &.with-logo {
        display: grid;
        grid-template-columns: 25% 50% 25%;
      }

      &_image {
        padding-left: 40px;
        img {
          height: 75px;
        }
      }

      &_title {
        text-align: center;
        align-self: flex-end;

        .reset-title{
          font-size: 25px;
          line-height: 33px;
        }
      }
    }

    &_content {
      width: 72.5%;
      margin: auto;
      padding-top: 3vh;

      &_title{
        display: flex;
        justify-content: center;

        h2{
          font-size: 18px;
          font-weight: normal;
        }
      }

      &_code-inputs{
        display: flex;
        justify-content: space-evenly;
        padding: 3vh 0;

        .code-input{
          width: 8.5%;
          outline: none;
          padding: 2.5% 0;          
          text-align: center;
          border: 1px solid #1475A2;
          border-radius: 8px;
        }
      }


      &_input-raw:first-child {
        margin-bottom: 15px;
      }      
      &_forget-password {
        padding-left: 8px;
        a {
          text-decoration: none;
          color: rgba(20, 117, 162, 1);
          font-size: 8px;
          font-weight: 600;
        }
      }
      &_submit {
        display: flex;
        justify-content: center;
        padding-top: 15px;

        button {
          background: #1475a2;
          border-radius: 10px;
          border: none;
          color: white;
          padding: 9px 9.8%;
          font-size: 14px;
          cursor: pointer;
        }
      }


      .auth-input {
        width: 100%;
        outline: none;
        padding: 15px;
        background: #ffffff;
        border: 1px solid #1d1d1d;
        border-radius: 12px;
      }
    }
  }
}

//  media
// $breakpoint-768
@media (max-width: 1200px) {
  .auth_inner {
    width: 70vw;

    &_header {
      img {
        height: 65px;
      }
    }
  }
}

@media (max-width: 768px) {
  .auth_inner {
    width: 90vw;
    &-success{
      p{
        font-size: 20px;
      }
    }
    &_header {
      &_image {
        padding-left: 20px;
        img {
          height: 50px;
        }
      }
      .reset-title{
        font-size: 18px;
      }
    }
    &_content {
      width: 80%;

      .auth-input {
        padding: 12px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 400px) {
  .auth_inner {
    width: 98vw;
    padding-top: 5vh;

    &_header {
      &.with-logo {
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
      }

      &_image {
        padding: 0;
        justify-self: center;
      }
    }
    &_content {
      width: 80%;

      .auth-input {
        padding: 9px 7px;
      }
      &_submit {
        button {
          background: #1475a2;
          border-radius: 10px;
          color: white;
          padding: 9px 15%;
          font-size: 12px;
        }
      }
      .code-input{
        width: 15%;
        padding: 4% 0;
      }
    }
  }
}
