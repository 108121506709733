.modal-parent{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.507);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content{
    background-color: white;
    min-width: 32.7604166667vw;
    padding: 25px;
}

.closeIcon{
    cursor: pointer;
}

.delete-item-modal{
    &_header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading{
            display: flex;
            align-items: center;
            justify-content: center;

            span{
                margin-left: 12px;
            }
        }
    }
    &_content{
        padding-top: 24px;
        padding-left: 44px;
    }
    &_confirm{
        padding-top: 70px;
        display: flex;
        justify-content: flex-end;

        >p{
            cursor: pointer;
            margin: 0 16px;
        }
    }
}
.menu-drawer-add-modal{
    &_header{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        &_logo{
            align-self: center;
        }
    }
    &_content{

        label{
            text-align: right;
        }

        &_image{
            display: flex;
            justify-content: center;
        }

        .input-file-target{
            cursor: pointer;
            object-fit: cover;
        }
        
        &_grid-container{
            margin-top: 8.88vh;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 60px;
        }

        &_row{
            &_inputs{

                select{
                    width: 323px;
                    border: none;
                    border-bottom: 1px solid rgba(227, 227, 205, 1);
                    /* border: 1px solid #AEADB3; */
                    font-weight: 350;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.1em;
                    outline: none;
                    padding-left: 20%;
                    color: #49494D;
                }

                input{
                    width: 192px;
                    padding: 8px 0;
                    background: #FAFAFA;
                    border: 1px solid #AEADB3;
                    box-sizing: border-box;
                    border-radius: 16px;
                    padding-left: 20px;
                    margin-right: 20px;
                    outline: none;
                }
            }
        }

        &_submit{
            display: flex;
            justify-content: center;
            margin-top: 12.5vh;

            button{
                background-color: #1475A2;
                border-radius: 20px;
                outline: none;
                border: none;
                padding: 11px 36px;
                color: rgb(255, 255, 255);
                cursor: pointer;
            }

        }

    }
}